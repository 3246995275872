@import "./variables.module";

html,
body {
  padding: 0;
  margin: 0;
  font-family: Poppins, sans-serif;
  background-color: $body-background-color;
  @include media(">tablet") {
    scrollbar-width: 16px;
    scrollbar-color: #3f0a67 #1d0148;
  }

  &.hide_scrollbars {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  @include media(">tablet") {
    &::-webkit-scrollbar {
      background-color: #1d0148;
      width: 16px;
    }

    &::-webkit-scrollbar-track {
      background-color: #1d0148;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #3f0a67;
      border-radius: 16px;
      border: 5px solid #1d0148;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #3f0a67;
      border: 4px solid #1d0148;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }
}

*,
*::after,
*::before {
  box-sizing: border-box;
  font-family: inherit;
  margin: 0;
  padding: 0;
  outline: none;
  scrollbar-width: 16px;
  scrollbar-color: #3f0a67 #1d0148;
}
// 1D0148
// 3F0A67
@include media(">tablet") {
  *::-webkit-scrollbar {
    background-color: #1d0148;
    width: 16px;
  }
  *::-webkit-scrollbar-track {
    background-color: #1d0148;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #3f0a67;
    border-radius: 16px;
    border: 5px solid #1d0148;
  }
  *::-webkit-scrollbar-thumb:hover {
    background-color: #3f0a67;
    border: 4px solid #1d0148;
  }
  *::-webkit-scrollbar-button {
    display: none;
  }
}

*:active,
*:focus {
  outline: none;
}

body::before {
  content: "";
  display: block;
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}

body.radio-view::before {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(/Radio.All.Radio.png);
}

body > div {
  z-index: 1;
  position: relative;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  font-family: Poppins, sans-serif;
}

html {
  font-size: 62.5%;

  @include media(">=desktop2560") {
    font-size: 70%;
  }

  @include media(">=desktop3840") {
    font-size: 100%;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

html,
body {
  &.modal_open {
    /* when modal active */
    touch-action: none;
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    /* Other browsers */
    overscroll-behavior: none;
  }
}

.faded {
  opacity: 0.4;
}

.loader {
  padding: 4.8rem 3.2rem;
  display: flex;
  justify-content: center;

  &.fullpage {
    width: 100%;
    height: 100vh;
    align-items: center;

    svg {
      width: 6.4rem;
      height: 6.4rem;
    }
  }

  svg {
    width: 4.8rem;
    height: 4.8rem;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

// VLYBY
.vlyby_container_main {
  position: fixed;
  bottom: 3.2rem;
  right: 3.2rem;
  z-index: 1;
  width: 40rem;

  @include media("<tablet") {
    right: 1.6rem;
    width: 24rem;
    bottom: 10rem;
    max-width: 50vh;

    &.vlyby_mini_player_active {
      bottom: 17rem;
    }
  }
  @include media("<phoneXL") {
    width: 18rem;
  }

  .vlyby_container_main_inner {
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;
  }
  .vlyby_container_main_content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  iframe {
    border: none;
  }
}
