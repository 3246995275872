@import "../../../styles/variables.module";

.footer {
  color: $color-light;
  padding: 2.4rem 3.2rem;
  margin: 0 auto;
  position: absolute;

  @include media("<desktop720p") {
    padding-bottom: 15rem;
    padding: 2.4rem 2.4rem 15rem;
    background-position: center top, center bottom;
  }
  @include media("<tablet") {
    padding: 2.4rem 1.6rem 15rem;

  }


  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    menu {
      display: flex;
      flex-wrap: wrap;
      padding-left: 1.6rem;
      max-width: 100%;
      
      @include media("<tablet") {
        flex-direction: column;
        display: flex;
        padding-bottom: 4.8rem;
        padding-left: 0rem;
      }
    }

    a {
      display: inline-block;
      padding: 0.1rem 0;
      margin: 0 1rem 0.1rem 0;
      font-size: 1.5rem;
      font-weight: 400;

      @include media("<tablet") {
        margin-bottom: 1.6rem;
        font-size: 1.4rem;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .social_button {
      svg {
        width: 2.2rem;
        height: 2.2rem;
      }

      &.facebook {
        width: 1rem;
      }
    }
    .social_button + .social_button {
      margin-left: 4rem;
    }
  }
}
