@import "../../../styles/variables.module";

$header-background-desktop: rgba($color-light, 0.05);
$mobile-menu-height: 37rem;

body:global(.low-performance) {
  .profile,
  .menu_item,
  .mobile_menu_wrapper,
  .menu_items {
    transition: none !important;
  }

  .logo_wrapper {
    .logo,
    a {
      transition: none !important;
    }
  }
}

body:global(.arrow-navigation) {
  @include media(">phoneXL") {
    .profile {
      &:focus {
        outline: none;
        background-color: rgba(93, 108, 146, 0.35);
        border-radius: 100%;
        transform: scale(1.1);
      }
    }
    .menu_items {
      .menu_item:focus {
        outline: none;
        background-color: rgba(93, 108, 146, 0.35);
        opacity: 1;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
}

.navigation_mobile {
  position: fixed;
  bottom: 0;
  bottom: constant(safe-area-inset-bottom, 0);
  bottom: env(safe-area-inset-bottom, 0);
  left: 0;
  right: 0;
  z-index: $z-index-base + 1;
  pointer-events: none;

  @include media(">tablet") {
    display: none;
  }

  &_inner {
    height: 6rem;
    align-items: center;
    display: flex;
    justify-content: center;
    background: $color-black;

    a {
      pointer-events: all;
    }
  }

  .navigation_item {
    display: grid;
    justify-items: center;
    padding: 0 2rem;
    &.active {
      .label {
        color: $color-light;
        font-weight: bold;
      }
      svg * {
        stroke: $color-light;
      }
    }
    .label {
      font-size: 1.1rem;
      font-weight: 400;
      color: #929292;
    }

    svg {
      height: 2.4rem;
      width: 2.4rem;

      * {
        stroke: #929292;
      }
    }
  }
  a:last-child {
    svg {
      padding: 0.2rem;
    }
  }
}

.profile {
  display: flex;
  justify-content: flex-end;
  transition: 0.5s all;
  margin-left: 2.4rem;
  transition: all 0.5s;

  .icon,
  .icon_wrapper p {
    transition: all 0.5s;
    opacity: 1;
  }

  &:hover {
    .icon,
    .icon_wrapper p {
      opacity: 1;
    }
  }

  @include media("<=desktop720p") {
    margin-left: 0;
    display: none;
  }
  @include media("<tablet") {
    margin-left: 0;
  }

  &.mobile {
    @include media(">tablet") {
      display: none;
    }
  }
  .icon {
    position: absolute;
    top: 1.2rem;
    left: 2.6rem;
    width: 2.3rem;
    height: 2.3rem;
    opacity: 0.4;

    &_wrapper {
      background-color: rgba($color-light, 0.1);

      height: 5rem;
      position: relative;
      border-radius: 2.3rem;

      p {
        color: $color-light;
        font-weight: 400;
        font-size: 2.2rem;
        line-height: 3.4rem;
        padding-left: 6.2rem;
        padding: 0.8rem 2.6rem 0.8rem 6.2rem;
        opacity: 0.4;
      }
    }

    .search_mobile & {
      top: 1.1rem;
      left: 0;
    }
  }
}
.header {
  position: relative;

  .inner_wrapper {
    padding: 1.6rem 3.2rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: $player-fullscreen-z-index - 1;
    position: relative;
    height: 9.9rem;

    @include media(">desktop720p") {
      padding: 1.6rem 5rem;
    }
    @include media("<=desktop720p") {
      height: 8.8rem;
    }
    @include media("<=tablet") {
      padding: 1.6rem;
    }
  }

  .left_wrapper {
    flex: 0 0 33.3333%;
    display: flex;
    align-items: center;

    @include media("<=desktop720p") {
      flex-grow: 1;
    }
    
  }
  .search_desktop {
    flex: 0 0 33.3333%;

    @include media("<=desktop720p") {
      display: none;
    }
  }

  .logo_wrapper {
    flex: 0 0;

    .logo {
      height: 4.8rem;
      width: 17.4rem;
      transition: 0.5s all;

      position: relative;

      @include media("<=tablet") {
        width: 116px;
        height: 32px;
      }
    }
    a {
      transition: 0.5s all;
      display: inline-block;
    }
  }

  .player_wrapper {
    flex: 1;

    @include media("<=tablet") {
      position: fixed;
      bottom: 6rem;
      bottom: calc(6rem + constant(safe-area-inset-bottom, 0));
      bottom: calc(6rem + env(safe-area-inset-bottom, 0));
      left: 0;
      right: 0;
      z-index: $player-minimized-z-index;
    }
  }

  .side {
    flex: 0 0 33.3333%;
    display: flex;
    justify-content: center;
    align-content: center;

    @include media("<=desktop720p") {
      flex: 0 1 auto;
      align-content: flex-end;
      justify-content: flex-end;
    }
    
    @include media("<=tablet") {
      display: none;
    }
    .side_items {
      display: grid;
      gap: 1.6rem;
      grid-auto-flow: column;
      justify-content: space-between;
      margin-left: 1.6rem;
    }
    .menu_items {
      flex: 0;
      display: flex;
      justify-content: space-between;

      .menu_item + .menu_item {
        @include media(">desktop1600") {
          margin-left: 2.4rem;
        }
      }

      .menu_items_inner {
        display: flex;
      }

      .menu_item {
        display: flex;
        align-items: center;
        opacity: 0.6;
        justify-content: flex-end;
        transition: 0.5s all;
        border-radius: 2.4rem;
        padding: 0.4rem 2.4rem;

        @include media("<desktop720p") {
          margin: 0 0.8rem;
        }

        &:hover {
          opacity: 1;
          background: rgba($color-light, 0.15);
        }

        &.active {
          opacity: 1;
          background-color: $color-whitelabel-main;

          span {
            color: $color-black;
            text-shadow: none;
          }
          svg {
            path {
              stroke: $color-black;
            }
          }
        }

        span {
          color: $color-light;
          font-size: 2.2rem;
          font-weight: 400;
          margin: 0 0 0 1.6rem;
          white-space: nowrap;

          @include media("<desktop720p") {
            display: none;
          }
        }

        &_icon {
          height: 3.2rem;
          width: 2.4rem;

          path {
            stroke: $color-light;
          }

          &_wrapper {
            display: flex;

            @include media("<desktop720p") {
              width: 4.6rem;
              justify-content: center;
            }
          }

          &.icon_home {
          }
          &.icon_favs {
            width: 2.6rem;
          }
        }
      }
    }
  }

  .mobile_menu_wrapper {
    display: none;
    transition: 0.5s all;
    left: 0;
    right: 0;

    @include media("<tablet") {
      display: block;
      z-index: $player-fullscreen-z-index - 1;
      width: 100%;
      position: absolute;
      top: 100%;
      overflow: hidden;
      height: 0;
    }

    &.mobile_active {
      height: $mobile-menu-height;
      background-color: #002e53;

      .menu_items {
        z-index: $z-index-base;
      }
    }

    .menu_items {
      transition: 0.5s all;

      @include media("<tablet") {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        position: absolute;
        left: 0;
        right: 0;
        height: 100%;
        padding: 2.4rem 0;
        background-color: #0d3255;
      }

      .menu_item {
        padding: 0.8rem 0;

        span {
          @include media("<tablet") {
            display: inline-block;
          }
        }
      }
    }
  }
}
.search_mobile {
  flex: 7.2rem 0 0;
  border-radius: 50px;

  .icon_wrapper {
    height: 4rem;
    width: 7.2rem;
    text-align: center;
  }
  .profile {
    text-align: center;
    display: block;
    width: 100%;

    svg {
      display: inline-block;
      position: static;
      vertical-align: middle;
    }
  }

  @include media(">desktop720p") {
    display: none;
  }
}
