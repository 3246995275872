@import "../../../styles/variables.module";
.wrapper {
  z-index: 66;
  position: fixed;
}
.toast {
  background-color: $color-primary;
  border-radius: 1rem;
  color: $color-light;
  font-size: 1.8rem;
  margin: 1rem 0;
  padding: 1rem 3rem;
  max-width: 45rem;
  
  @media (prefers-reduced-motion: no-preference) {
    animation-duration: 300ms;
  }

  &.slide_down {
    animation-fill-mode: forwards;
    animation-name: slideDown;
  }

  &.slide_up {
    animation-fill-mode: backwards;
    animation-name: slideUp;
  }

  &_error {
    background-color: $color-danger;
  }

  &_container {
    position: fixed;
    z-index: 999;

    &.bottom-left {
      bottom: 2rem;
      left: 12rem;
    }

    &.bottom-right {
      bottom: 2rem;
      right: 2rem;
    }

    &.bottom-center {
      bottom: 2rem;
      left: 50%;
      transform: translate(-50%, 0);
    }

    &.top-left {
      left: 12rem;
      top: 2rem;
    }

    &.top-right {
      right: 6rem;
      top: 2rem;
    }

    &.top-center {
      left: 50%;
      top: 2rem;
      transform: translate(-50%, 0);
    }
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-20px);
  }

  to {
    transform: translateY(0);
  }
}
